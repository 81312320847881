<template>
  <div>
    <div>Process Full View Page</div>
    <div>Coming soon...</div>
  </div>
</template>

<script>

export default {
  components: {
  },
  directives: {
  },
  data() {
    return {

    }
  },
}
</script>

<style lang="scss" scoped>

</style>
